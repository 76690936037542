import React from 'react'
import { makeStyles } from 'tss-react/mui'
import HelpIcon from '@mui/icons-material/HelpOutline'
import { IconProps, Link, Theme, lighten } from '@mui/material'
import { Button } from 'ui'

type Props = {
  title: string
  Icon: React.ComponentType<IconProps>
  subtitle?: string
  docsLink?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: 88,
    padding: theme.spacing(2),
    border: theme.palette.mode === 'dark' ? 'none' : `1px solid ${theme.palette.divider}`,
    borderColor: lighten(theme.palette.divider, theme.palette.mode === 'light' ? 0.1 : -0.25),
    boxShadow: '0px 1px 12px rgba(21, 31, 41, 0.05)',
    borderRadius: 8,
  },
  logoContainer: {
    width: 40,
    height: 40,
    background: `${theme.palette.mode === 'dark' ? '#17232E' : '#ECEDEE'}`,
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
  },
  titleContainer: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 22,
  },
  subtitleContainer: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 12,
  },
  docsLink: {
    color: theme.palette.text.secondary,
  },
}))

export const NewPageTitle = ({ title, subtitle, Icon, docsLink }: Props) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <Icon style={{ width: 32, height: 32 }} />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.titleContainer}>
          <span>{title}</span>
        </div>
        {subtitle && (
          <div className={classes.subtitleContainer}>
            <span>{subtitle}</span>
          </div>
        )}
      </div>
      <div>
        {docsLink && (
          <Link href={docsLink} target="_blank">
            <Button
              variant="outlined"
              startIcon={<HelpIcon />}
              style={{ height: 32, padding: '8px, 12px, 8px, 12px' }}
              className={classes.docsLink}
            >
              Docs
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}

// export default NewPageTitle
