import { Theme } from '@mui/material'
import { Components, alpha } from '@mui/material'
import { cyan } from '@mui/material/colors'
import { Colors } from '../colors'
import { customColors } from '../customColors'

const lightComponentsOverrides: Components<Omit<Theme, 'components'>> = {
  MuiLink: {
    styleOverrides: {
      root: {
        color: cyan['700'],
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        border: `1px solid ${Colors.grey.t92}`,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: 'none',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${Colors.grey.t92}`,
        '&:hover': {
          backgroundColor: '#f2fafe',
        },
        '&:last-child': {
          borderBottom: 'none',
        },
      },
      head: {
        backgroundColor: Colors.grey.t96,
        '&:hover': {
          backgroundColor: Colors.grey.t96,
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        border: `1px solid ${Colors.grey.t92}`,
        '&.Mui-disabled': {
          color: '#606060',
        },
      },
      input: {
        '&::placeholder': {
          color: customColors.EerieBlack,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      // Give tooltips a light background so that syntax highlighting looks good
      tooltip: {
        color: Colors.grey['100'],
        backgroundColor: alpha(Colors.grey['t92'], 0.95),
      },
    },
  },
}

export default lightComponentsOverrides
