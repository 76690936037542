// @ts-nocheck
// TODO: fix all type errors here
import { ChangeEvent, HTMLAttributes } from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  darken,
  lighten,
} from '@mui/material'
import { AutocompleteRenderOptionState, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { TextField } from '../TextField'
import { Autocomplete } from './Autocomplete'
import { CommonInputDefaultProps, CommonInputProps } from './BaseInput'

const useStyles = makeStyles<void, 'hasClearIcon'>()((theme: Theme, _params, classes) => ({
  clearIndicator: {
    color: theme.palette.text.secondary,
  },
  root: {
    cursor: 'pointer',
    '& *': {
      cursor: 'pointer',
    },
    '&:not(.Mui-focused):hover fieldset': {
      borderColor: `${(theme.palette.mode === 'dark' ? lighten : darken)(
        theme.palette.borderColor.main,
        0.25,
      )} !important`,
    },
    '& .MuiAutocomplete-input': {
      padding: '0px !important',
    },
    '& .MuiOutlinedInput-root': {
      flexWrap: 'wrap',
    },
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(2),
    [`.${classes.hasClearIcon} &`]: {
      paddingRight: theme.spacing(6),
    },
  },
  hasClearIcon: {},
}))

export type OptionType = {
  [key: string]: any
}

export interface CommonSelectProps<T>
  extends Omit<CommonInputProps, 'autoComplete' | 'autoFocus' | 'endAdornment' | 'startAdornment'> {
  /** If `true`, the input can be cleared. */
  clearable?: boolean
  /** Used to create a custom option from `input` value. */
  createCustomOption?: (event: ChangeEvent) => T
  /** Used to determine the disabled state for a given option. */
  getOptionDisabled?: (option: T) => boolean
  /** Used to determine the string value for a given option. It's used to fill the input. */
  getOptionLabel?: (option: T) => string
  /** If provided, the options will be grouped under the returned string. */
  groupBy?: (option: T) => string
  /**
   * Array of options.
   *
   * **Option signature:**
   * `{ [key: string]: any }`
   */
  options: T[]
  /** display default text, should be paired with MenuProps */
  renderValue?: () => string
  /** Render the option, use `getOptionLabel` by default. */
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
  ) => JSX.Element
  /** If `true`, text search is enabled. */
  searchable?: boolean
}

export const CommonSelectDefaultProps = {
  ...CommonInputDefaultProps,
  clearable: false,
  searchable: false,
}

interface Props<T> extends CommonSelectProps<T> {
  customClasses?: { [name: string]: any }
  multiple?: boolean
  onChange?: (selectedValue: any) => void
  getOptionSelected: (option: T) => boolean
  renderTags?: (values: T[], getTagProps: AutocompleteGetTagProps) => JSX.Element[]
  selectedValue: T | T[] | null
  setSelectedValue: (selectedValue: any) => void
  InputProps?: any
  dataQa?: any
  limitTags?: number
  filterOptions?: (options: T[], state: any) => T[]
  compact?: boolean
}

export function BaseSelect<T>({
  customClasses = {},
  style,
  clearable,
  createCustomOption,
  disabled,
  error,
  fullWidth,
  getOptionDisabled,
  getOptionLabel,
  getOptionSelected,
  groupBy,
  gutterBottom,
  helperText,
  label,
  large,
  multiple,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  renderTags,
  required,
  renderOption,
  searchable,
  selectedValue,
  setSelectedValue,
  dataQa,
  limitTags,
  filterOptions,
}: Props<T>) {
  const { classes } = useStyles(undefined, {
    props: {
      classes: customClasses,
    },
  })
  const theme = useTheme()

  const TextFieldComponent = (props: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...props}
        style={{ ...style }}
        error={error}
        fullWidth={fullWidth}
        gutterBottom={gutterBottom}
        helperText={helperText}
        className={classes.root}
        inputProps={{
          className: classes.input,
          ...props.inputProps,
          ...(!searchable && {
            onChange: () => {},
            style: {
              color: 'transparent',
              textShadow:
                !disabled && !multiple && selectedValue
                  ? `0 0 0 ${theme.palette.text.primary}`
                  : 'none',
              ...(multiple ? { flexGrow: 1, width: 0, minWidth: '30px' } : {}),
            },
          }),
        }}
        InputProps={{
          ...props.InputProps,
          className: '', // this is needed to make selects the same height as other inputs
        }}
        label={label}
        labelRequired={required}
        large={large}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={createCustomOption}
        placeholder={placeholder}
        data-qa={dataQa}
      />
    )
  }

  return (
    <Autocomplete
      blurOnSelect
      classes={classes}
      disableClearable={!clearable}
      disabled={disabled}
      fullWidth={fullWidth}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      groupBy={groupBy}
      filterOptions={filterOptions}
      id={name}
      multiple={multiple}
      onChange={(_event, selectedValue) => {
        setSelectedValue(selectedValue)
        onChange && onChange(selectedValue)
      }}
      options={options}
      renderInput={TextFieldComponent}
      renderOption={renderOption}
      renderTags={renderTags}
      selectOnFocus={!!searchable}
      value={selectedValue}
      limitTags={limitTags}
    />
  )
}

BaseSelect.defaultProps = CommonSelectDefaultProps
