import { PriorityCounts } from './exposures/PriorityCounts'

/**
 * All ASM graph types
 */
export enum Asm {
  Hostname = 'Asm::Hostname',
  IPAddress = 'Asm::IPAddress',
  IPAddressRange = 'Asm::IPAddressRange',
  GithubRepo = 'Asm::GithubRepo',
  SnykTarget = 'Asm::SnykTarget',
  Seed = 'Asm::Seed',
  Collection = 'Asm::Collection',
  AwsResource = 'Asm::AwsResource',
  TenableNsFolder = 'Asm::TenableNsFolder',
  TenableScRepository = 'Asm::TenableScRepository',
}

/**
 * All ASM permissions
 */
export const ASM_PERMISSIONS = {
  Read: 'asm_read',
  Comment: 'asm_comment',
  Scan: 'asm_scan',
  Manage: 'asm_manage',
}

/**
 * The the permission type
 * @example 'asm_read'
 */
export type AsmPermission = (typeof ASM_PERMISSIONS)[keyof typeof ASM_PERMISSIONS]

/**
 * The the permissions boolean map
 * of all available permissions of currently logged in user.
 * @example { canRead: true, canScan: true, canComment: false, canManage: false }
 */
export type AsmPermissionsBooleanMap = {
  [K in keyof typeof ASM_PERMISSIONS as `can${K}`]?: Boolean
}

export const UNCOMPLETED_TASK_STATUSES = ['created', 'pending', 'running', 'blocked']
export const COMPLETED_TASK_STATUSES = [
  'failed',
  'succeeded',
  'skipped',
  'canceled',
  'timedout',
  'unreachable',
]

export type TaskStatus =
  | (typeof UNCOMPLETED_TASK_STATUSES)[number]
  | (typeof COMPLETED_TASK_STATUSES)[number]

export interface BaseRecord {
  id: string
  created_at: string
  updated_at: string
}

export interface User {
  id: string
  fullname: string
  email: string
  avatar_url: string
}

export type SeverityCounts = {
  high?: number
  medium?: number
  low?: number
}

export interface Scan extends BaseRecord {
  completed: boolean
  completed_at: string
  canceled: boolean
  asset_scan_started?: boolean
  asset_limit?: number
  asset_limit_exceeded?: boolean
  asset_id?: string
  asset?: Asset
  seed_id?: string
  seed?: Seed
  collection_id?: string
  collection?: Collection
  new_exposure_counts?: PriorityCounts
  total_exposure_counts?: PriorityCounts
  resolved_exposure_counts?: PriorityCounts
  new_technology_versions_count?: number
  total_technology_versions_count?: number
  asset_count?: number
  new_assets_count?: number
  description?: string
  task_types?: string[]
}

export interface Collection extends BaseRecord {
  name: string
  description?: string
  organization_id?: string
  slug?: string
  priority?: number
  asset_count?: number
  last_scanned_at?: string
  scan_in_progress?: boolean
}

export const TECHNOLOGY_SOURCES = {
  None: 'none', // Not yet scanned or failed to scan in both live and cached modes
  Live: 'live', // Technologies have been detected using the live scan
  Cache: 'cache', // Technologies have been detected using the cached scan
}
export type TechnologiesSource = (typeof TECHNOLOGY_SOURCES)[keyof typeof TECHNOLOGY_SOURCES]

export interface Asset extends BaseRecord {
  type?: (typeof Asm)[keyof typeof Asm]
  name: string
  under_management: boolean
  collection_id: string
  collection: Collection
  seed_id?: string
  private_ip?: boolean
  edgio_ip?: string
  scannable?: boolean
  friendly_type?: string
  scan_in_progress?: boolean
  collection_scan_in_progress?: boolean
  linked_hostnames?: string[]
  last_scan?: Scan
  recent_scans?: Scan[]
  technologies_source?: TechnologiesSource
}
export interface Seed extends Asset {
  seed_type_id: string
}

export interface Technology extends BaseRecord {
  name: string
  normalized_name: string
  organization_id?: string
  categories?: string[]
  due_date: string
  last_detected_at?: string
  collections?: Collection[]
  cve_counts?: SeverityCounts
}

export interface TechnologyVersion extends BaseRecord {
  name: string
  number?: string
  organization_id?: string
}

export interface Rule extends BaseRecord {
  collections?: Collection[]
  order?: number
  enabled?: boolean
  rule_description?: string
  action_type?: string
  severity_action_type?: string
  exposure_severity?: number
  asset_filter_type?: string
  collection_filter_type?: string
  rule_criteria?: RuleCriteria[]
}

export interface RuleCriteria extends BaseRecord {
  criterion_type?: string
  operator?: string
  value?: string
  secondary_operator?: string
  secondary_value?: string
}

export interface Task extends BaseRecord {
  status: TaskStatus
  completed: boolean
  completed_at: string
  canceled: boolean
  scan_id: string
  scan?: Scan
  type: string
  asset_id?: string
  asset?: Asset
  seed_id?: string
  seed?: Seed
}

export interface Cve extends BaseRecord {
  external_id?: string
  description?: string
  summary?: string
  severity?: number
  impact?: number
  exploitability?: number
  source?: string
  status?: string
  external_published_at?: string
  external_modified_at?: string
  cvss_metrics_v31?: MetricV31[]
  cvss_metrics_v2?: MetricV2[]
}

export interface MetricV2 {
  cvssData: {
    [key: string]: string
  }
  type: string
  source: string
  impactScore: number
  exploitabilityScore: number
  baseSeverity: string
}

export interface MetricV31 {
  cvssData: {
    [key: string]: string
  }
  type: string
  source: string
  impactScore: number
  exploitabilityScore: number
}

export interface ExposureStatus {
  id: string
  name: string
  order: number
}

export const LOG_LEVELS = {
  debug: 'debug',
  info: 'info',
  warn: 'warn',
  error: 'error',
}
export type LogLevel = (typeof LOG_LEVELS)[keyof typeof LOG_LEVELS]

export const LOG_VISIBILITIES = {
  internal: 'internal',
  external: 'external',
}

export type LogVisibility = (typeof LOG_VISIBILITIES)[keyof typeof LOG_VISIBILITIES]

export interface TaskLogEntry {
  timestamp: string
  message: string
  visibility: LogVisibility
  level: LogLevel
}
