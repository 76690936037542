import { Theme } from '@mui/material'
import { Components } from '@mui/material'
import { Colors } from '../colors'
import lightPaletteOptions from '../light/palette'

const baseComponentsOverrides: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: '0.875rem',
        lineHeight: 1.43,
      },
      a: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        paddingBottom: 0,
      },
      subheader: {
        marginTop: 8,
        fontSize: '0.875rem',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minWidth: 90,
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      message: {
        color: 'white',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        padding: '2px 12px',
        minHeight: 32,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          borderColor: Colors.blue[100],
        },
        '&.Mui-focused': {
          boxShadow: `0 0 5px ${Colors.blue.t50}`,
          border: `1px solid ${Colors.blue[100]}`,
        },
        '&.Mui-error': {
          borderColor: Colors.red[100],
          '&.Mui-focused': {
            boxShadow: `0 0 5px ${Colors.red.t50}`,
          },
        },
      },
      formControl: {
        fontWeight: 400,
      },
      input: {
        fontWeight: 400,
        height: 28,
        fontSize: '0.875rem',
        lineHeight: 1.4,
        padding: '0 !important',
        '&::placeholder': {
          color: 'white',
          fontWeight: '400 !important',
        },
      },
      multiline: {
        height: 'auto',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: 'none',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: Colors.red[100],
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: '72px',
      },
    },
  },
  MuiTable: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        borderCollapse: 'collapse',
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: 'none',
      },
      sizeSmall: {
        paddingTop: 8,
        paddingBottom: 8,
        height: 48,
      },
      sizeMedium: {
        paddingTop: 8,
        paddingBottom: 8,
        height: 64,
      },
      head: {
        fontSize: 12,
        textTransform: 'uppercase',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          paddingTop: 2,
          paddingBottom: 2,
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: '0px 4px',
        },
      },
      option: {
        fontSize: 14,
      },
      groupLabel: {
        fontSize: 12,
      },
      popper: {
        padding: 0,
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'medium',
    },
    styleOverrides: {
      root: {
        color: Colors.grey.t50,
      },
      sizeMedium: {
        height: 32,
        width: 32,
        '& svg': {
          width: 18,
          height: 18,
        },
      },
      sizeSmall: {
        height: 26,
        width: 26,
        '& svg': {
          width: 18,
          height: 18,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontWeight: '400 !important',
        fontSize: 12,
        lineHeight: 1.2,
        marginBottom: 4,
        color: `${Colors.grey.t50} !important`,
      },
      asterisk: {
        color: Colors.green[100],
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        alignItems: 'center',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        // @ts-ignore
        borderColor: lightPaletteOptions.borderColor.main,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        height: '22px',
        padding: '9px 14px 7px 14px',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '& span': {
          borderColor: Colors.grey.t50,
        },
      },
    },
  },
}

export default baseComponentsOverrides
