import { Theme } from '@mui/material'
import { Components } from '@mui/material'
import { cyan } from '@mui/material/colors'
import { Colors } from '../colors'
import darkPaletteOptions from './palette'

const darkComponentOverrides: Components<Omit<Theme, 'components'>> = {
  MuiLink: {
    styleOverrides: {
      root: {
        color: cyan['A200'],
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        border: `1px solid ${Colors.grey[100]}`,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        backgroundColor: '#17232E',
        borderStyle: 'hidden',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        backgroundColor: Colors.grey.s70,
        borderBottom: `1px solid ${Colors.grey[100]}`,
        '&:hover': {
          backgroundColor: '#061923',
        },
        '&:last-child': {
          borderBottom: 'none',
        },
        '&.MuiTableRow-hover:hover': {
          backgroundColor: '#061923',
        },
      },
      head: {
        backgroundColor: Colors.grey.s30,
        '&:hover': {
          backgroundColor: Colors.grey.s30,
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&:hover': {
          color: 'white',
          fontWeight: 500,
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        border: `1px solid ${Colors.grey[100]}`,
        '&.Mui-disabled': {
          color: '#C4C4C4',
        },
        backgroundColor: `${Colors.grey.s70} !important`,
      },
      input: {
        '&::placeholder': {
          color: 'white',
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        opacity: 0.6,
        '&.Mui-error': {
          opacity: 1,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'unset',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        // @ts-ignore
        borderColor: darkPaletteOptions.borderColor.main,
      },
    },
  },
}

export default darkComponentOverrides
